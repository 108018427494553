<template>
  <div id="Blockchains">
    <p class="chain-announcement">Our view on each chain</p>
    <div class="chains">
      <p
        class="chain"
        v-for="(chain, idx) in upcomingchains" :key="idx"
        @click="selectChain(chain)"
        :class="{'selected': current_chain == chain}">{{chain}}</p>

    </div>
    <div class="divider"></div>
    <div class="chain-info">
      <div v-for="(bc, idx) in blockchains[displayBlockNotes].markdowns" :key="idx" v-html="bc"></div>
    </div>
  </div>
</template>

<script>
import blockchains_json from '@/assets/json/blockchains.json'

export default {
  name: 'Chains',
  components: {
  },
  data(){
    return{
      current_chain: 'Ethereum',
      upcomingchains: ['Ethereum', 'BSC', 'Solana', 'Polkadot', 'Polygon', 'Cardano', 'xDai', 'Avalanche'],
      blockchains: blockchains_json
    }
  },
  methods:{
    selectChain(c){
      this.current_chain = c
    }
  },
  computed:{
    displayBlockNotes(){
      // if(this.current_chain == 'Ethereum'){
      //   return 0
      // }else if(this.current_chain == 'BSC'){
      //   return 1
      // }else if(this.current_chain == 'Cardano'){
      //   return 2
      // }else if(this.current_chain == 'BSC'){
      //   return 1
      // }else if(this.current_chain == 'Cardano'){
      //   return 2
      // }
      // return 0

      switch (this.current_chain) {
        case 'Ethereum':
          return 0
        case 'BSC':
          return 1
        case 'Solana':
          return 2
        case 'Polkadot':
          return 3
        case 'Polygon':
          return 4
        case 'Cardano':
          return 5
        case 'xDai':
          return 6
        case 'Avalanche':
          return 7
      }
      return 0
    }
  }
}
</script>
<style scoped>
#Blockchains{
  width: 100%;
  height: 100%;
  min-height: 100vh;
  color: #fff;
}
.chain-announcement{
  color: #fff;
  margin: 2rem auto;
}
.chains{
  width: 100%;
  display: flex;
  justify-content: space-evenly;;
  align-items: center;
  transition: 1s;
}
.chain{
  color: #fff;
  font-size: 15px;
  transition: 1s;
  font-weight: bold;
  cursor:pointer;
}
.selected{
  border: 2px solid #fff;
  padding: .6rem 1rem;
  border-radius: 5px;
}
.chain:hover{
  transform: translateY(-10px);
}
.divider{
  width: 80%;
  margin: 1rem auto;
  background-color: #000;
  height: 3px;
  border-radius: 10px;

}
.chain-info{
  text-align: left;
  width: 90%;
  margin: 2rem auto;
}
@media only screen and (max-width: 600px) {

  .selected{
    border: 1px solid #fff;
    padding: .3rem .5rem;
    border-radius: 5px;
  }
  .chain{
    font-size: .8rem;
  }
}

</style>
